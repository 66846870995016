
body * {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    color: #fff;
}

.wrapper {
    background: #6B9B7E;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1em;
}

.title {
    margin: 0 0 1em;
    padding: 0;
    font: normal 1.2em sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.controls {
    margin: 1rem 0 0;
}

svg .svg-elem-1 {
    stroke-dashoffset: 885.0800170898438px;
    stroke-dasharray: 885.0800170898438px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
}

svg.active .svg-elem-1 {
    stroke-dashoffset: 0;
    fill: rgb(0, 115, 63);
}

svg .svg-elem-2 {
    stroke-dashoffset: 498.4599609375px;
    stroke-dasharray: 498.4599609375px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
}

svg.active .svg-elem-2 {
    stroke-dashoffset: 0;
    fill: rgb(255, 221, 0);
}

svg .svg-elem-3 {
    stroke-dashoffset: 565.3914184570312px;
    stroke-dasharray: 565.3914184570312px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
}

svg.active .svg-elem-3 {
    stroke-dashoffset: 0;
    fill: rgb(255, 221, 0);
}

svg .svg-elem-4 {
    stroke-dashoffset: 616.395751953125px;
    stroke-dasharray: 616.395751953125px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
}

svg.active .svg-elem-4 {
    stroke-dashoffset: 0;
    fill: rgb(255, 255, 255);
}

svg .svg-elem-5 {
    stroke-dashoffset: 237.01339721679688px;
    stroke-dasharray: 237.01339721679688px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s;
}

svg.active .svg-elem-5 {
    stroke-dashoffset: 0;
    fill: rgb(0, 115, 63);
}

svg .svg-elem-6 {
    stroke-dashoffset: 269.85260009765625px;
    stroke-dasharray: 269.85260009765625px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s;
}

svg.active .svg-elem-6 {
    stroke-dashoffset: 0;
    fill: rgb(0, 115, 63);
}

svg .svg-elem-7 {
    stroke-dashoffset: 256.23175048828125px;
    stroke-dasharray: 256.23175048828125px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s;
}

svg.active .svg-elem-7 {
    stroke-dashoffset: 0;
    fill: rgb(0, 115, 63);
}

svg .svg-elem-8 {
    stroke-dashoffset: 387.90838623046875px;
    stroke-dasharray: 387.90838623046875px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s;
}

svg.active .svg-elem-8 {
    stroke-dashoffset: 0;
    fill: rgb(0, 115, 63);
}

svg .svg-elem-9 {
    stroke-dashoffset: 240.7259979248047px;
    stroke-dasharray: 240.7259979248047px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.96s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s;
}

svg.active .svg-elem-9 {
    stroke-dashoffset: 0;
    fill: rgb(0, 115, 63);
}

svg .svg-elem-10 {
    stroke-dashoffset: 244.35409545898438px;
    stroke-dasharray: 244.35409545898438px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.08s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s;
}

svg.active .svg-elem-10 {
    stroke-dashoffset: 0;
    fill: rgb(0, 115, 63);
}

svg .svg-elem-11 {
    stroke-dashoffset: 228.59222412109375px;
    stroke-dasharray: 228.59222412109375px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s;
}

svg.active .svg-elem-11 {
    stroke-dashoffset: 0;
    fill: rgb(0, 115, 63);
}

svg .svg-elem-12 {
    stroke-dashoffset: 143.62123107910156px;
    stroke-dasharray: 143.62123107910156px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3199999999999998s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s;
}

svg.active .svg-elem-12 {
    stroke-dashoffset: 0;
    fill: rgb(0, 115, 63);
}

svg .svg-elem-13 {
    stroke-dashoffset: 231.01393127441406px;
    stroke-dasharray: 231.01393127441406px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.44s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s;
}

svg.active .svg-elem-13 {
    stroke-dashoffset: 0;
    fill: rgb(0, 115, 63);
}

svg .svg-elem-14 {
    stroke-dashoffset: 337.7879638671875px;
    stroke-dasharray: 337.7879638671875px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.56s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s;
}

svg.active .svg-elem-14 {
    stroke-dashoffset: 0;
    fill: rgb(0, 115, 63);
}

svg .svg-elem-15 {
    stroke-dashoffset: 231.5214080810547px;
    stroke-dasharray: 231.5214080810547px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.68s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s;
}

svg.active .svg-elem-15 {
    stroke-dashoffset: 0;
    fill: rgb(0, 115, 63);
}

svg .svg-elem-16 {
    stroke-dashoffset: 217.1434326171875px;
    stroke-dasharray: 217.1434326171875px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7999999999999998s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7999999999999998s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s;
}

svg.active .svg-elem-16 {
    stroke-dashoffset: 0;
    fill: rgb(0, 115, 63);
}

svg .svg-elem-17 {
    stroke-dashoffset: 231.01119995117188px;
    stroke-dasharray: 231.01119995117188px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.92s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4000000000000004s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.92s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4000000000000004s;
}

svg.active .svg-elem-17 {
    stroke-dashoffset: 0;
    fill: rgb(0, 115, 63);
}

svg .svg-elem-18 {
    stroke-dashoffset: 54.34162902832031px;
    stroke-dasharray: 54.34162902832031px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.04s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.04s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s;
}

svg.active .svg-elem-18 {
    stroke-dashoffset: 0;
    fill: rgb(0, 115, 63);
}

svg .svg-elem-19 {
    stroke-dashoffset: 214.15127563476562px;
    stroke-dasharray: 214.15127563476562px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.16s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.16s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s;
}

svg.active .svg-elem-19 {
    stroke-dashoffset: 0;
    fill: rgb(0, 115, 63);
}

svg .svg-elem-20 {
    stroke-dashoffset: 231.03201293945312px;
    stroke-dasharray: 231.03201293945312px;
    fill: transparent;
    -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.28s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.7s;
    transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.28s,
    fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.7s;
}

svg.active .svg-elem-20 {
    stroke-dashoffset: 0;
    fill: rgb(0, 115, 63);
}
